import {useContext} from "react";
import {UserContext} from "../Contexts";
import {useLocation, Navigate} from "react-router-dom";

function RequireAuth({children, loginPath}) {
  const {user} = useContext(UserContext);
  const location = useLocation();
  return user && !user.isAnonymous ? children : <Navigate to={loginPath} state={{from: location}} replace />;
}

export default RequireAuth;
