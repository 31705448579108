import {useContext, useEffect, useState} from "react";
import './Login.scss';
import {getAuth, setPersistence, browserSessionPersistence, signInWithEmailAndPassword} from "firebase/auth";
import {UserContext} from "../Contexts";
import {useLocation, useNavigate} from 'react-router-dom';

export default function Login() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const {user} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleUsername = e => {
    setError('');
    setUsername(e.target.value);
  };

  const handlePassword = e => {
    setError('');
    setPassword(e.target.value);
  };

  const canLogin = username && password;

  const handleLogin = () => {
    if (canLogin) {
      setLoading(true);
      const auth = getAuth();
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithEmailAndPassword(auth, username, password)
            .then(() => {
              navigate(state?.from || '/admin/controller');
            })
            .catch(err => {
              setLoading(false);
              setError('Password incorrect');
            });
        })
        .catch(err => {
          setLoading(false);
          alert('Failed to initialise persistence storage');
        });
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  useEffect(() => {
    if (user && !user.isAnonymous) {
      navigate(state?.from || '/admin/controller');
    }
  }, [navigate, user, state]);

  return <div className={"Login"}>
    <div className={"form-group"}>
      <label>Username</label>
      <input className="form-control" type={"text"} name={"username"} value={username} onChange={handleUsername} disabled={loading} onKeyDown={handleKeyDown} />
    </div>
    <div className={"form-group"}>
      <label>Password</label>
      <input className="form-control" type={"password"} name={"password"} value={password} onChange={handlePassword} disabled={loading} onKeyDown={handleKeyDown} />
    </div>
    <div className={"actions"}>
      <div className={"error"}>{error}</div>
      <button className={"btn btn-primary"} onClick={handleLogin} disabled={loading && !canLogin}>Login</button>
    </div>
  </div>
}
