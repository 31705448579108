/**
 * Add comma
 * @param x
 * @returns {string}
 * https://stackoverflow.com/a/2901298/534862
 */
export const addComma = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * Check if it's touch device
 * @returns {boolean}
 * https://stackoverflow.com/a/4819886/534862
 */
export const isTouchDevice = () => (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));