import PlayerScreen from "./PlayerScreen";
import Instruction from "./components/Instruction";
import BigButton from "./components/BigButton";
import './JumpPlayerScreen.scss';

import ButtonImage from '../../assets/game3-button1.svg';
import ButtonDepressedImage from '../../assets/game3-button2.svg';
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import StartOverlay from "./components/StartOverlay";
import confettiModule from "canvas-confetti";

function JumpPlayerScreen ({config, user, onStart, onComplete}) {

  const [loadedAt] = useState((new Date()).toISOString());
  const [resetAt, setResetAt] = useState(null);

  const [target, setTarget] = useState(config?.seconds || 7);
  const [elapsed, setElapsed] = useState(0);
  const [ticks, setTicks] = useState(0);
  const [startedAt, setStartedAt] = useState(null);
  const [stoppedAt, setStoppedAt] = useState(null);
  const [error, setError] = useState(config?.error || 1);
  const [stage, setStage] = useState(config?.stage || 1);

  const tooEarly = elapsed < (target - error) * 1000;
  const tooLate = elapsed > (target + error) * 1000;
  const justNice = !tooEarly && !tooLate;

  useEffect(() => {
    setTarget(config.target || 7);
    setError(config.error || 1);
    setStage(config.stage || 1);
    setResetAt(config.reset_at || null);
  }, [config]);

  const handleClick = () => {
    if (startedAt) {
      const stoppedAt = dayjs();
      setStoppedAt(stoppedAt);

      // calculate immediately and store
      const elapsed = stoppedAt.valueOf() - startedAt.valueOf();
      const tooEarly = elapsed < (target - error) * 1000;
      const tooLate = elapsed > (target + error) * 1000;
      const justNice = !tooEarly && !tooLate;
      if (justNice) {
        const extra = {};
        extra[`/players/${user.uid}/jump/${stage}/completed_at`] = stoppedAt.toISOString();
        extra[`/players/${user.uid}/jump/${stage}/score`] = elapsed / 1000;
        onComplete('jump', stoppedAt, elapsed / 1000, extra);

        setTimeout(() => {
          confettiModule({
            particleCount: 130,
            spread: 40,
            origin: { y: 0.7 }
          });
        }, 400);
      }
    }
  }

  useEffect(() => {
    // Force stop
    if (elapsed > ((target + error) * 1000)) {
      setStoppedAt(dayjs());
    }
  }, [startedAt, elapsed, target, error]);

  useEffect(() => {
    if (startedAt) {
      if (stoppedAt) {
        setElapsed(stoppedAt.valueOf() - startedAt.valueOf());
      } else {
        setElapsed(dayjs().valueOf() - startedAt.valueOf());
      }
    }
  }, [stoppedAt, startedAt, ticks]);

  useEffect(() => {
    let intv;
    if (startedAt && !stoppedAt) {
      intv = setInterval(() => {
        setTicks(ticks => ticks + 1);
      }, 37);
    }
    return () => {
      clearInterval(intv);
    }
  }, [stoppedAt, startedAt]);

  useEffect(() => {
    // whenever stage change we reset the game
    setStartedAt(null);
    setElapsed(0);
    setStoppedAt(null);
  }, [stage]);

  useEffect(() => {
    if (resetAt > loadedAt) {
      setElapsed(0);
      setStartedAt(null);
      setStoppedAt(null);
    }
  }, [loadedAt, resetAt]);

  const handleStart = () => {
    const start = dayjs();
    setStartedAt(start);

    const extra = {};
    extra[`/players/${user.uid}/jump/${stage}/started_at`] = start.toISOString();
    onStart('jump', start, extra);
  }

  return <PlayerScreen className={"JumpPlayerScreen"}>
    <div className={"stage"}>
      STAGE {stage}
    </div>
    <div className={"stopwatch"}>
      <div className={"s"}>{Math.trunc(elapsed / 1000)}</div>
      <div className={"ms"}>.{(elapsed % 1000).toString().padStart(3, '0')}s</div>
    </div>
    {stoppedAt ? <>
      {tooEarly && <Instruction>Oh no, you are too early.</Instruction>}
      {tooLate && <Instruction>Oh no, you are too late.</Instruction>}
      {justNice && <Instruction>Nice catch!<br />You stopped the timer at {elapsed/1000}s.</Instruction>}
    </> : <Instruction>
      Press the button <br /> at exactly {target} seconds!
    </Instruction>}
    {!stoppedAt && <div className={"click-area"}>
      <BigButton image={ButtonImage} depressedImage={ButtonDepressedImage} onClick={handleClick} disabled={stoppedAt || !startedAt} />
      {!startedAt && <StartOverlay onStart={handleStart} />}
    </div>}
  </PlayerScreen>;
}

export default JumpPlayerScreen;
