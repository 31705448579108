import './SpeedBar.scss';

const sections = [
  {name: 's1', start: 0},
  {name: 's2', start: 11},
  {name: 's3', start: 37},
  {name: 's4', start: 89},
];

function SpeedBar({from, to, speed}) {

  // given speed, we find the ranges
  const percentage = Math.max(0, Math.min(1, speed / (to - from))) * 100;

  return <div className={"SpeedBar"}>
    <div className={"speed"}>{speed.toFixed(1)} CPS</div>
    <div className={"bar"}>
      {sections.map(s => <div className={"section section-" + s.name} key={s.name} style={{left: s.start + '%'}} />)}
    </div>
    <div className={"indicator"}>
      <div className={"arrow"} style={{left: percentage + '%'}} />
    </div>
  </div>
}

export default SpeedBar;