import {getDatabase, ref, set, update} from "firebase/database";

export const cast = (game = '', castConfig = null, playerConfig = null) => {
  const db = getDatabase();

  const updates = {
    'cast/game': game,
    'player/game': game
  };

  if (castConfig) {
    updates[`cast/${game}`] = castConfig;
  }

  if (playerConfig) {
    updates[`player/${game}`] = playerConfig;
  }

  return update(ref(db), updates);
};
