import Piece from "../Piece";

class Parallelogram extends Piece {


  _createElement() {
    const w = this.boardWidth / 2;
    const el = document.createElement('div');
    el.classList.add('tg-parallelogram');
    el.style.width = w + 'px';
    el.style.height = w / 2 + 'px';
    el.style.backgroundColor = this.color;
    return el;
  }

  transform(t) {
    this.el.style.left = t.x + 'px';
    this.el.style.top = t.y + 'px';
  }

  plain() {
    this.el.style.backgroundColor = 'white';
  }

  colorize() {
    this.el.style.backgroundColor = this.color;
  }
}

export default Parallelogram;