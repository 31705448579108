import PlayerScreen from "./PlayerScreen";
import Instruction from "./components/Instruction";
import './RunPlayerScreen.scss';
import SpeedBar from "./run/SpeedBar";
import BigButton from "./components/BigButton";

import ButtonImage from '../../assets/game2-button1.svg';
import ButtonDepressedImage from '../../assets/game2-button2.svg';
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import StartOverlay from "./components/StartOverlay";
import {getDatabase, set, ref, increment} from "firebase/database";
import confettiModule from "canvas-confetti";
import {addComma} from "../../common";

// Using speed bands: 0 -> 1 -> 4 -> 10 -> 11
//                      S1   S2  (S3)   S4
const FROM = 0;
const TO = 11;
const MIN = 4;
const MAX = 10;

// Every 50 clicks we submit
const CLICKS_INCREMENT_DELTA = 50;

function RunPlayerScreen ({config, user, onStart}) {

  const [loadedAt] = useState((new Date()).toISOString());
  const [resetAt, setResetAt] = useState(null);

  const [ticks, setTicks] = useState(0);
  const [startedAt, setStartedAt] = useState(null);
  const [clicks, setClicks] = useState(0);
  const [clickReferences, setClickReferences] = useState([]);
  const [speed, setSpeed] = useState(0);

  const [endedAt, setEndedAt] = useState(null);

  useEffect(() => {
    setEndedAt(config.ended_at);
    setResetAt(config.reset_at || null);
  }, [config]);

  useEffect(() => {
    if (endedAt) {
      setTimeout(() => {
        confettiModule({
          particleCount: 130,
          spread: 40,
          origin: { y: 0.7 }
        });
      }, 400);
    }
  }, [endedAt]);

  const handleClick = () => {
    if (startedAt) {
      setClicks(clicks => clicks + 1);
      clickReferences.push(new Date());
      setClickReferences([...clickReferences]);

      // on every x clicks, submit update to my total clicks
      if (clicks % CLICKS_INCREMENT_DELTA === 0) {
        const db = getDatabase();
        set(ref(db, `/players/${user.uid}/run/score`), increment(CLICKS_INCREMENT_DELTA));
      }
    }
  }

  useEffect(() => {
    // we crop off the clicks within the time passed
    const from = new Date(Date.now() - 1500);
    const filtered = clickReferences.filter(r => r > from);
    setClickReferences([...filtered]);

    // The speed will be the clicks / [last - first]
    if (filtered.length > 3) {
      const timeSpend = filtered[filtered.length - 1] - filtered[0];
      setSpeed(filtered.length / timeSpend * 1000);
    } else {
      setSpeed(0);
    }

  }, [ticks]); // do not ref to clickRef

  useEffect(() => {
    if (resetAt > loadedAt) {
      setStartedAt(null);
      setClicks(0);
      setEndedAt(null);
    }
  }, [loadedAt, resetAt]);

  useEffect(() => {
    const intv = setInterval(() => {
      setTicks(ticks => ticks + 1);
    }, 500);
    return () => {
      clearInterval(intv);
    }
  }, []);

  const handleStart = () => {
    const start = dayjs();
    setStartedAt(start);
    onStart('run', start);
  }

  return <PlayerScreen className={"RunPlayerScreen"}>
    {!endedAt && <SpeedBar from={FROM} to={TO} speed={speed} />}
    {endedAt ? <Instruction>Congratulations! All charged up!
      {clicks > 0 && <> You have contributed {addComma(clicks)} clicks!</>}
    </Instruction> : <Instruction>
      {speed > MAX && "You are too fast! Slow down and maintain it within the green zone!"}
      {speed >= MIN && speed <= MAX && "Maintain your clicking speed within the green zone!"}
      {speed < MIN && "Keep pressing the button to keep the bar within the green zone!"}
    </Instruction>}
    <div className={"click-area"}>
      <BigButton image={ButtonImage} depressedImage={ButtonDepressedImage} onClick={handleClick} disabled={endedAt || !startedAt} />
      {!endedAt && !startedAt && <StartOverlay onStart={handleStart} />}
    </div>
  </PlayerScreen>;
}

export default RunPlayerScreen;
