import './Browser.scss';
import {useRef, useState} from "react";

const Browser = ({className='', url: defaultUrl}) => {

  const iframeRef = useRef();
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(defaultUrl);
  const [tempUrl, setTempUrl] = useState(url);

  const handleRefresh = () => {
    setLoading(true);
    iframeRef.current.contentWindow.location.reload();
  };

  const handleLoad = () => {
    setLoading(false);
  }

  const handleUrlChange = e => {
    setTempUrl(e.target.value);
  }

  const handleRestore = () => {
    setUrl(defaultUrl);
    setTempUrl(defaultUrl);
  };

  const handleInputFocus = e => {
    e.target.select();
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setLoading(true);
      setUrl(tempUrl);
    } else if (e.key === 'Escape') {
      setTempUrl(url);
    }
  };

  return <div className={"Browser " + (loading ? 'loading ' : '') + className}>
    <div className={"toolbar"}>
      <div className={"toolbar-button"} onClick={handleRefresh}><div className={"material-icons icon"} title={"Refresh page"}>refresh</div></div>
      <div className={"address-bar"}>
        <input type={"text"} className={"form-control"} value={tempUrl} onKeyDown={handleKeyDown} onChange={handleUrlChange} onFocus={handleInputFocus} ref={inputRef} />
      </div>
      {url !== defaultUrl && <div className={"toolbar-button"} onClick={handleRestore}><div className={"material-icons icon"} title={"Reset to original page"}>restore</div></div>}
    </div>
    <div className={"screen"}>
      <iframe src={url} seamless="seamless" onLoad={handleLoad} ref={iframeRef}/>
    </div>
  </div>
};

export default Browser;
