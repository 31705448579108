import './GameSelector.scss';
import {games} from "../../config";
import Section from "./Section";

function GameSelector({game = '', onGame = () => {}, disabled = false}) {

  return <Section className={"GameSelector"}>
    <div className={"game-buttons"}>
      <button className={"btn game-button" + (game === '' ? ' active' : '')} onClick={() => onGame('')} disabled={disabled}>[Holding Screen]</button>
      {games.map(g => <button className={"btn game-button" + (game === g.name ? ' active' : '')}
                           onClick={() => onGame(g.name)} disabled={disabled}
                           key={g.name}>
        {g.label}
      </button>)}
    </div>
  </Section>;
}

export default GameSelector;
