import './Section.scss';

function Section({children, className = '', header}) {
  return <div className={"Section " + className}>
    {header && <div className={"header"}>{header}</div>}
    <div className={"body"}>
      {children}
    </div>
  </div>
}

export default Section;
