import './CastButton.scss';
import Section from "../../Section";
import {cast} from "../../../../api";

function CastButton ({casting = false, disabled = false, onClick = () => {}}) {
  const handleClick = () => {
    if (casting) {
      cast();
      // if (window.confirm('Confirm stop casting?')) {
      //   // empty the screen
      // }
    } else {
      onClick();
    }
  }

  return <Section className={"CastButton"}>
    <button className={"btn" + (casting ? ' btn-casting' : '')} disabled={disabled} onClick={handleClick}>
      {casting ? 'STOP CASTING' : 'Cast Now'}
    </button>
  </Section>;
}

export default CastButton;
