import './Sprite.scss';
import Canvas from "./Canvas";
import {forwardRef, useEffect, useState} from "react";

let tick = 0;
let scaled = false;
const Sprite = forwardRef(({width, height, images: imageSources, fps = 6, stop = false, loop = true}, ref) =>  {

  const c = imageSources.length;
  const [images, setImages] = useState([]);

  // always reset on leaving
  useEffect(() => {
    return () => {
      tick = 0;
      scaled = false;
    }
  }, []);

  useEffect(() => {
    const imgs = imageSources.map(src => {
      const img = new Image(width, height);
      img.src = src;
      return img;
    });
    setImages(imgs);
  }, [width, height, imageSources]);

  // Throttling for fps:
  // https://stackoverflow.com/a/19772220/534862

  const fpsInterval = 1000 / fps;
  let then = Date.now();

  const draw = (ctx, canvas) => {
    if (images.length === 0 || stop) return;

    // We only resize canvas once
    if (!scaled) {
      // resize canvas for blurry image
      const {width: w, height: h} = canvas.getBoundingClientRect()
      if (canvas.width !== w || canvas.height !== h) {
        const { devicePixelRatio:ratio=1 } = window
        canvas.width = w*ratio
        canvas.height = h*ratio
        ctx.scale(ratio, ratio)
      }
      scaled = true;
    }

    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(images[tick % c], 0, 0, width, height);

    if (fps > 0) {
      const now = Date.now();
      const elapsed = now - then;

      if (elapsed > fpsInterval) {
        then = now - (elapsed % fpsInterval);
        if (loop) {
          tick++;
        } else if (tick < c - 1) {
          tick++;
        }
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(images[tick % c], 0, 0, width, height);
      }
    }
  }

  return <div className={"Sprite"} ref={ref}>
    <Canvas
    style={{width, height}}
    draw={draw}
    />
  </div>;
});

export default Sprite;