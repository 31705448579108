class Hint {


  constructor(length = 0, x = 0, y = 0, rotate = 0) {
    this.length = length;
    this.x = x;
    this.y = y;
    this.rotate = rotate;
  }

  createElement() {
    const el = document.createElement('div');
    el.classList.add('hint');
    el.style.height = this.length + 'px';
    el.style.left = this.x + 'px';
    el.style.top = this.y + 'px';
    el.style.transform = `rotate(${this.rotate}deg)`;
    return el;
  }
}

export default Hint;