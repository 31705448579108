import CastScreen from "./CastScreen";
import './RunCastScreen.scss';
import {useEffect, useState} from "react";
import Goal from "./components/Goal";
import Sprite from "../../components/Sprite";

import RunSprite1 from '../../assets/game2-run1.svg';
import RunSprite2 from '../../assets/game2-run2.svg';
import RunSprite3 from '../../assets/game2-run3.svg';
import RunSprite4 from '../../assets/game2-run4.svg';

import Battery from '../../assets/game2-battery-c.svg';
import Lightning from "./components/Lightning";

function RunCastScreen ({config}) {

  const [value, setValue] = useState(0);
  const [vw, setVw] = useState(window.innerWidth);
  const [speed, setSpeed] = useState(5);
  const [loadedAt] = useState((new Date()).toISOString());
  const [lastLightning, setLastLightning] = useState(null);

  const [circleIn, setCircleIn] = useState(false);
  const [circleOut, setCircleOut] = useState(false);

  useEffect(() => {
    setValue(config.value);
    setLastLightning(config.last_lightning);
  }, [config]);

  useEffect(() => {
    const onResize = () => {
      setVw(window.innerWidth);
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  useEffect(() => {
    let intv, intv2, intv3;
    if (lastLightning > loadedAt) {
      intv = setTimeout(() => {
        setCircleIn(true);
      }, 2000);
      intv2 = setTimeout(() => {
        setCircleIn(false);
        setCircleOut(true);
      }, 6000);
      intv3 = setTimeout(() => {
        setCircleIn(false);
        setCircleOut(false);
      }, 8000);
    }
    return () => {
      clearInterval(intv);
      clearInterval(intv2);
      clearInterval(intv3);
    }
  }, [loadedAt, lastLightning]);

  // re-offset the battery shape
  const percentageFrom = 14;
  const percentageTo = 80;
  const percentage = percentageFrom + (percentageTo - percentageFrom) * (value / 100);

  return <CastScreen className={"RunCastScreen" + (value === 100 ? ' completed' : '')}>
    <Goal colorFrom={"#0C5639"} colorTo={"#ACD24E"}>
      Charge the battery up!
    </Goal>
    <div className={"progress"}>
      {(circleIn || circleOut) && <div className={"transition-circle " + (circleIn ? ' in' : '') + (circleOut ? ' out' : '')} />}
      {lastLightning > loadedAt && <Lightning key={lastLightning} />}
      <div className={"progress-bar"}>
        <div className={"battery foreground"} style={{clipPath: `polygon(${100 - percentage}% 0, 100% 0, 100% 100%, ${100 - percentage}% 100%)`}}>
          <img src={Battery} />
        </div>
        <div className={"battery background"}>
          <img src={Battery} />
        </div>
      </div>
      <div className={"treadmill"}>
        <Sprite
          fps={speed}
          width={vw * 0.426}
          height={vw * 0.3506}
          images={[
            RunSprite1, RunSprite2, RunSprite3, RunSprite4
          ]}
        />
      </div>
    </div>
  </CastScreen>;
}

export default RunCastScreen;
