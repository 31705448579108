import './ProgressBar.scss';
import {useEffect, useRef, useState} from "react";
import {isTouchDevice} from "../../../../common";
function ProgressBar({
   value, adminValue = 66,
   started = false, duration = 90,
   onStart = () => {}, onStop = () => {}, onAdminValue = () => {}
}) {

  const dragHandleMarginOffset = 24;
  const dragHandlerRef = useRef();
  const adminBarRef = useRef();
  const [elapsed, setElapsed] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [tempAdminValue, setTempAdminValue] = useState(adminValue);
  const valuePercentage = Math.min(1, value / 100) * 100;
  const adminPercentage = Math.min(1, Math.max(value, tempAdminValue) / 100) * 100;

  const [dragging, setDragging] = useState(false);
  const [dragStartOffsetLeft, setDragStartOffsetLeft] = useState(0);
  const [dragStartClientX, setDragStartClientX] = useState(0);

  useEffect(() => {
    setRemainingSeconds(duration - elapsed);
    setProgressPercentage(Math.min(1, elapsed / duration) * 100);
  }, [duration, elapsed]);

  useEffect(() => {
    let intv;
    if (started) {
      intv = setInterval(() => {
        setElapsed(elapsed => {
          if (started) {
            return elapsed + 1;
          }
          return elapsed;
        });
      }, 1000);
    } else {
      setElapsed(0);
    }
    return () => {
      clearInterval(intv);
    }
  }, [started, duration]);

  const handleStartStop = () => {
    if (started) {
      onStop();
    } else {
      onStart();
    }
  }

  useEffect(() => {
    const handleMouseUp = () => {
      const percentage = parseInt(dragHandlerRef.current.style.left);
      setTempAdminValue(percentage);
      onAdminValue(percentage);
      dragHandlerRef.current.parentElement.classList.remove('dragging');
      setDragging(false);
    }
    document.addEventListener('touchend', handleMouseUp);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  }, [onAdminValue, dragHandlerRef, setDragging]);

  useEffect(() => {
    const handleMouseMove = e => {
      e.preventDefault();
      e.stopPropagation();

      if (dragging) {
        const computedStyle = window.getComputedStyle(dragHandlerRef.current);
        const parentWidth = parseInt(dragHandlerRef.current.parentElement.offsetWidth);
        const marginOffset = Math.abs(parseInt(computedStyle.marginLeft));
        const clientX = e.touches && e.touches.length > 0 ? e.touches[0].clientX : e.clientX;
        const offset = clientX - dragStartClientX;
        const left = Math.max(0, Math.min(parentWidth, dragStartOffsetLeft + offset + marginOffset));
        const percentage = Math.max(valuePercentage, Math.round(left / parentWidth * 100));

        dragHandlerRef.current.style.left = percentage + '%';
        adminBarRef.current.style.width = percentage + '%';

        // display hint
        if (percentage > 20) {
          dragHandlerRef.current.previousSibling.style.right = (100 - percentage) + '%';
          dragHandlerRef.current.previousSibling.textContent = Math.round(percentage) + '%';
        }
      }
    }
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('mousemove', handleMouseMove);
    }
  }, [dragging, dragStartOffsetLeft, dragStartClientX, dragHandlerRef, valuePercentage]);

  const handleDragStart = e => {
    dragHandlerRef.current.parentElement.classList.add('dragging');
    setDragStartOffsetLeft(e.target.offsetLeft);
    setDragStartClientX(e.touches && e.touches.length > 0 ? e.touches[0].clientX : e.clientX);
    setDragging(true);
  };

  useEffect(() => {
    setTempAdminValue(adminValue);
  }, [adminValue]);

  return <div className={"ProgressBar"}>
    <div className={"help-text"}>Drag the handle to move the progress:</div>
    <div className={"bar-wrapper"}>
      <div className={"bar-holder"}>
        <div className={"lines"}>
          <div className={"line value"} style={{left: `${valuePercentage}%`}}/>
          <div className={"line progress"} style={{left: `${progressPercentage}%`}}/>
        </div>
        <div className={"drag-area"}>
          <div className={"drag-hint"} />
          <div className={"drag-handler"}
               ref={dragHandlerRef}
               onMouseDown={handleDragStart}
               onTouchStart={handleDragStart}
               style={{left: `${adminPercentage}%`}}
          />
        </div>
        <div className={"bar value"} style={{width: `${valuePercentage}%`}}>
          <div className={"icon material-icons"}>directions_run</div>
          {valuePercentage > 20 && <div className={"label"}>{Math.ceil(valuePercentage)}%</div>}
        </div>
        <div className={"bar admin"} style={{width: `${adminPercentage}%`}} ref={adminBarRef} />
        <div className={"bar progress"} style={{width: `${progressPercentage}%`}}>
          <div className={"icon material-icons"}>hourglass_top</div>
          {remainingSeconds / duration < 0.8 && <div className={"label" + (remainingSeconds < 0 ? ' text-danger' : '')}>{remainingSeconds}s</div>}
        </div>
      </div>
    </div>

    <div className={"actions"}>
      <button className={"btn btn-primary" + (started ? ' btn-danger' : '')} onClick={handleStartStop}>
        {started ? 'Reset Timer' : 'Start Timer'}
      </button>
    </div>
  </div>;
}

export default ProgressBar;
