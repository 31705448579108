import './EndPlayerCast.scss';
import Section from "../../Section";
const EndPlayerCast = ({
  onEndCast = () => {}, onEndPlayer = () => {},
  endCastDisabled = false, endPlayerDisabled = false,
  hideEndPlayer = false, hideEndCast = false
}) => {

  const handleEndCast = () => {
    if (!endCastDisabled) {
      onEndCast();
    }
  }

  const handleEndPlayer = () => {
    if (!endPlayerDisabled) {
      onEndPlayer();
    }
  }

  return <Section className={"EndPlayerCast"}>
    {!hideEndCast && <button className={"btn btn-cast"} disabled={endCastDisabled} onClick={handleEndCast}>🎆 End Cast 🎆</button>}
    {!hideEndPlayer && <button className={"btn btn-player"} disabled={endPlayerDisabled} onClick={handleEndPlayer}>🎉 End Player 🎉</button>}
  </Section>;
};

export default EndPlayerCast;
