import Config from "./Config";
import './Config.scss';
import './JumpConfig.scss';
import CastButton from "./components/CastButton";
import {addComma} from "../../../common";
import Statistics from "./components/Statistics";
import Settings from "./components/Settings";
import ResetGameButton from "./components/ResetGameButton";
import {useEffect, useState} from "react";
import Section from "../Section";
import {getDatabase, ref, onValue, update, set} from "firebase/database";
import EndPlayerCast from "./components/EndPlayerCast";

const MODE_STILL = 'still';
const MODE_JUMP = 'jump';
const MAX_STAGES = 3;

const DEFAULT_TARGET = 7;
const DEFAULT_CAST_ERROR = 0.05;
const DEFAULT_PLAYER_ERROR = 1;


function JumpConfig({onCast, casting}) {

  const [value, setValue] = useState(6.85);
  const [target, setTarget] = useState(DEFAULT_TARGET);
  const [castError, setCastError] = useState(DEFAULT_CAST_ERROR)
  const [castMode, setCastMode] = useState(MODE_STILL);
  const [stage, setStage] = useState(1);
  const [playerError, setPlayerError] = useState(DEFAULT_PLAYER_ERROR);

  const [tempTarget, setTempTarget] = useState(target);
  const [tempCastError, setTempCastError] = useState(castError);
  const [tempPlayerError, setTempPlayerError] = useState(playerError);

  const [actualValue, setActualValue] = useState(0);
  const [adminValue, setAdminValue] = useState(0);
  const [adminValueTouched, setAdminValueTouched] = useState(false);
  const [showCastSecsInvalid, setShowCastSecsInvalid] = useState(false);


  const [totalPlayers, setTotalPlayers] = useState(0);
  const [playersStarted, setPlayersStarted] = useState(0);
  const [playersCompleted, setPlayersCompleted] = useState(0);
  const [playersTotalScore, setPlayersTotalScore] = useState(0);

  useEffect(() => {
    const db = getDatabase();
    const unsubscribeTotalPlayers = onValue(ref(db, 'total_players'), snapshot => {
      setTotalPlayers(snapshot.val() || 0);
    });
    return () => {
      unsubscribeTotalPlayers();
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const unsubscribePlayersStats = onValue(ref(db, `stats/jump/${stage}`), snapshot => {
      const val = snapshot.val() || {};
      setPlayersStarted(val.players_started || 0);
      setPlayersCompleted(val.players_completed || 0);
      setPlayersTotalScore(val.total_score || 0);
    });
    return () => {
      unsubscribePlayersStats();
    };
  }, [stage, target]);

  useEffect(() => {
    let avg = playersCompleted > 0 ? playersTotalScore / playersCompleted : 0;
    setActualValue(avg);
    if (!adminValueTouched) setAdminValue(avg.toFixed(3));
  }, [adminValueTouched, playersCompleted, playersTotalScore]);

  useEffect(() => {
    // listening to the config value
    const db = getDatabase();
    const unsubscribe = onValue(ref(db, 'controller/jump'), snapshot => {
      const config = snapshot.val() || {};
      setCastMode(config.mode || MODE_STILL);
      setStage(config.stage || 1);
      setCastError(config.cast_error || DEFAULT_CAST_ERROR);
      setPlayerError(config.player_error || DEFAULT_PLAYER_ERROR);
      setAdminValue(config.admin_value || 0);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleCast = () => {
    setCastMode(MODE_STILL);
    onCast('jump', {
      value, error: castError, target, mode: MODE_STILL, stage
    }, {
      target, error: playerError
    })
  };

  const handleJump = () => {
    if (adminValue >= (target - playerError) && adminValue <= (target + playerError)) {
      const db = getDatabase();
      const updates = {
        'cast/jump/mode': MODE_JUMP,
        'cast/jump/value': adminValue,
        'cast/jump/stage': stage,
      };
      const success = (adminValue >= target - castError) && (adminValue <= target + castError);
      updates['cast/jump/stages/s' + stage] = success ? 'Success' : 'Fail';
      update(ref(db), updates);
      setCastMode('jump');
    } else {
      setShowCastSecsInvalid(true);
    }
  };

  const handleReset = () => {
    setStage(1);
    setCastMode(MODE_STILL);
    setAdminValueTouched(false);

    setTarget(DEFAULT_TARGET);
    setTempTarget(DEFAULT_TARGET);
    setCastError(DEFAULT_CAST_ERROR);
    setTempCastError(DEFAULT_CAST_ERROR);
    setPlayerError(DEFAULT_PLAYER_ERROR);
    setTempPlayerError(DEFAULT_PLAYER_ERROR);

    const db = getDatabase();
    const updates = {};
    updates['cast/jump'] = {
      value: 0,
      target: DEFAULT_TARGET,
      error: DEFAULT_CAST_ERROR,
      mode: MODE_STILL,
      stage: 1
    };
    updates['controller/jump'] = null;
    updates['player/jump'] = {
      reset_at: (new Date()).toISOString(),
      target: DEFAULT_TARGET,
      error: DEFAULT_PLAYER_ERROR,
      stage: 1
    };
    updates['stats/jump'] = null;
    update(ref(db), updates);

    // Reset players
    onValue(ref(db, 'players'), snapshot => {
      const updates = {};
      snapshot.forEach(playerSnapshot => {
        updates[`players/${playerSnapshot.key}/jump`] = null;
      });
      update(ref(db), updates);
    }, {
      onlyOnce: true
    });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSaveSettings();
    }
  }

  const handleTempTargetChange = e => {
    setTempTarget(e.target.value);
  }

  const handleTempTargetBlur = e => {
    const v = Number(e.target.value);
    setTempTarget(isNaN(v) ? 7 : v);
  }

  const handleTempCastErrorChange = e => {
    setTempCastError(e.target.value);
  }

  const handleTempCastErrorBlur = e => {
    const v = Number(e.target.value);
    setTempCastError(isNaN(v) ? 0.5 : v);
  }

  const handleTempPlayerErrorChange = e => {
    setTempPlayerError(e.target.value);
  }

  const handleTempPlayerErrorBlur = e => {
    const v = Number(e.target.value);
    setTempPlayerError(isNaN(v) ? 3 : v);
  }

  const handleCancelSettings = () => {
    setTempCastError(castError);
    setTempPlayerError(playerError);
    setTempTarget(target);
  }

  const handleSaveSettings = () => {
    const db = getDatabase();
    setCastMode(MODE_STILL);
    setTarget(tempTarget);
    setPlayerError(tempPlayerError);
    setCastError(tempCastError);
    update(ref(db), {
      'controller/jump/target': tempTarget,
      'controller/jump/admin_value': adminValue,
      'controller/jump/cast_error': tempCastError,
      'controller/jump/player_error': tempPlayerError,
      'cast/jump/mode': MODE_STILL,
      'cast/jump/value': adminValue,
      'cast/jump/target': tempTarget,
      'cast/jump/error': tempCastError,
      'player/jump/target': tempTarget,
      'player/jump/error': tempPlayerError
    });
  }

  const handleNewStage = () => {
    const newStage = stage + 1;
    setStage(newStage);
    setAdminValue(0);
    setAdminValueTouched(false);

    const db = getDatabase();
    const updates = {};
    updates['cast/jump/stage'] = newStage;
    updates['cast/jump/mode'] = MODE_STILL;
    updates['player/jump/stage'] = newStage;
    updates['controller/jump/stage'] = newStage;
    update(ref(db), updates);
  }

  const handleAdminValue = e => {
    setAdminValue(e.target.value);
    setAdminValueTouched(true);
    setShowCastSecsInvalid(false);
  }

  const handleEndCast = () => {
    const db = getDatabase();
    update(ref(db), {
      'cast/jump/fireworks_at': (new Date()).toISOString(),
    });
  }

  const settingsDirty = tempTarget !== target ||
    tempPlayerError !== playerError ||
    tempCastError !== castError;

  return <Config className={"JumpConfig"}>
    <CastButton onClick={handleCast} casting={casting} />
    <Statistics>
      <div className={"row"}>
        <div className={"lbl"}>Total Players</div>
        <div className={"val"}>{addComma(totalPlayers)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Stage</div>
        <div className={"val"}>{stage}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Players Started</div>
        <div className={"val"}>{addComma(playersStarted)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Players Completed</div>
        <div className={"val"}>{addComma(playersCompleted)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Average Seconds</div>
        {playersCompleted > 0 ? <div className={"val"}>{(playersTotalScore / playersCompleted).toFixed(3)}s</div> : <div className={"val"}>0s</div>}
      </div>
    </Statistics>
    <Settings>
      <div className={"form-group"}>
        <label>Target Seconds</label>
        <input type={"number"} className={"form-control"} value={tempTarget} onChange={handleTempTargetChange} onBlur={handleTempTargetBlur} onKeyDown={handleKeyDown} />
      </div>
      <div className={"form-group"}>
        <label>Cast Error (in seconds)</label>
        <input type={"number"} className={"form-control"} value={tempCastError} onChange={handleTempCastErrorChange} onBlur={handleTempCastErrorBlur} onKeyDown={handleKeyDown} />
        <div className={"help-text"}>
          The # of seconds offset from the target seconds that are considered a successful jump, ie, [{tempTarget - tempCastError} - {tempTarget + Number(tempCastError)}] secs.
        </div>
      </div>
      <div className={"form-group"}>
        <label>User Error (in seconds)</label>
        <input type={"number"} className={"form-control"} value={tempPlayerError} onChange={handleTempPlayerErrorChange} onBlur={handleTempPlayerErrorBlur} onKeyDown={handleKeyDown} />
        <div className={"help-text"}>
          The # of seconds offset from the target seconds that are considered successful for the user, ie, [{tempTarget - tempPlayerError} - {tempTarget + Number(tempPlayerError)}] secs will be submitted and considered.
        </div>
      </div>
      <div className={"actions"}>
        <button className={"btn btn-primary"} disabled={!settingsDirty} onClick={handleSaveSettings}>Save Settings</button>
        {settingsDirty && <button className={"btn btn-text"} onClick={handleCancelSettings}>Cancel</button>}
      </div>
    </Settings>

    <Section className={"stage-control"}>
      <div className={"control"}>
        <div className={"user-score form-group"}>
          <label>User Secs:</label>
          <input type={"text"} value={actualValue.toFixed(3)} className={"form-control"} disabled={true} readOnly={true}/>
        </div>
        <div className={"admin-score form-group"}>
          <label>Cast Secs:</label>
          <input type={"text"} value={adminValue} className={"form-control"} onChange={handleAdminValue} disabled={!casting} readOnly={!casting} />
        </div>
        <div className={"action"}>
          {castMode === 'still' && <button className={"btn btn-primary btn-jump"} onClick={handleJump} disabled={!casting}>Jump!</button>}
          {castMode === 'jump' && <button className={"btn btn-danger btn-jump"} onClick={handleNewStage} disabled={!casting}>New Stage</button>}
        </div>
      </div>

      {showCastSecsInvalid && <div className={"help-text text-danger"}>"Cast Secs" must be between {target - playerError} to {target + playerError} secs.</div>}
      <div className={"help-text"}>The "Cast Secs" will be used to display on the cast screen instead. Press <strong>Jump!</strong> to display the result on the cast screen.</div>
    </Section>

    <EndPlayerCast
      endCastDisabled={stage < 3}
      hideEndPlayer={true}
      onEndCast={handleEndCast}
    />

    <ResetGameButton onReset={handleReset} />
  </Config>
}

export default JumpConfig;
