/**
 * "Custom" Tangram where we have 9 pieces ;)
 */
import SquareLarge from "./types/SquareLarge";
import SquareMedium from "./types/SquareMedium";
import TriangleLarge from "./types/TriangleLarge";
import TriangleMedium from "./types/TriangleMedium";
import TriangleSmall from "./types/TriangleSmall";
import Parallelogram from "./types/Parallelogram";
import Transformation from "./Transformation";
import Hint from "./Hint";
import {isTouchDevice} from "../../../common";

class Tangram {

  pieces = [];
  drops = [];
  hints = [];

  constructor(boardEl, pickupZoneEl, dropZoneEl,
              onComplete = () => {}) {
    this.isTouchDevice = isTouchDevice();
    this.boardEl = boardEl;
    this.pickupZoneEl = pickupZoneEl;
    this.dropZoneEl = dropZoneEl;

    this.disabled = true;
    this.onComplete = onComplete;

    // We check the dropzone to know the width
    const computedStyle = window.getComputedStyle(this.dropZoneEl);
    this.dropZoneWidth = parseInt(computedStyle.width);

    this._initPieces();
    this._initDropzone();
    this._initPickupZone();
    this.draggedPiece = null;
    this.lastX = 0;
    this.lastY = 0;

    // disabled state
    this.pickupZoneEl.classList.add('disabled');

    document.addEventListener('touchend', this._onDocumentMouseUp.bind(this));
    document.addEventListener('mouseup', this._onDocumentMouseUp.bind(this));
    document.addEventListener('touchmove', this._onDocumentMouseMove.bind(this));
    document.addEventListener('mousemove', this._onDocumentMouseMove.bind(this));
  }

  _initPieces() {
    // Create 9 pieces
    // - Square   L
    // - Square   M
    // - Triangle L 1
    // - Triangle L 2
    // - Triangle M 1
    // - Triangle M 2
    // - Triangle S 1
    // - Triangle S 2
    // - Parallelogram
    this.pieces.push(new SquareLarge(this.dropZoneWidth,'sl', '#EA40F7'));
    this.pieces.push(new SquareMedium(this.dropZoneWidth,'sm', '#64BBF9'));
    this.pieces.push(new TriangleLarge(this.dropZoneWidth,'tl1', '#F8D3AB'));
    this.pieces.push(new TriangleLarge(this.dropZoneWidth,'tl2', '#9FFBF0'));
    this.pieces.push(new TriangleMedium(this.dropZoneWidth,'tm1', '#C2D670'));
    this.pieces.push(new TriangleMedium(this.dropZoneWidth,'tm2', '#F3AEDD'));
    this.pieces.push(new TriangleSmall(this.dropZoneWidth,'ts1', '#68B79A'));
    this.pieces.push(new TriangleSmall(this.dropZoneWidth,'ts2', '#E9963F'));
    this.pieces.push(new Parallelogram(this.dropZoneWidth,'p', '#D66239'));
    this.drops = this.pieces.map(p => p.clone());
  }

  _initDropzone() {

    const zoneEl = this.dropZoneEl;

    // Clear dropzone
    while(zoneEl.firstChild) {
      zoneEl.removeChild(zoneEl.lastChild);
    }

    // Create drops and hints
    const pieces = document.createElement('div');
    pieces.classList.add('pieces');
    zoneEl.appendChild(pieces);

    // Init pieces locations
    const dw = this.dropZoneWidth;
    const transformations = {
      'sl': new Transformation(dw / 2, dw / 2, -45),
      'sm': new Transformation(dw / 4, dw / 2, 0),
      'tl1': new Transformation(dw / 2, dw / 2, 225),
      'tl2': new Transformation(dw / 2, dw / 2, 135),
      'tm1': new Transformation(dw / 2, dw / 2, 180),
      'tm2': new Transformation(dw, dw, 180),
      'ts1': new Transformation(0, dw / 2, 315),
      'ts2': new Transformation(dw / 4 * 3, dw / 4 * 3, 135),
      'p': new Transformation(Math.cos(45) * dw / 4, dw / 4 * 3, 0),
    };

    this.drops.forEach(piece => {
      const el = piece.createElement();
      pieces.append(el);
      if (transformations[piece.name]) {
        piece.transform(transformations[piece.name]);
      }
      piece.plain();
    });

    // hints
    const hints = document.createElement('div');
    hints.classList.add('hints');
    zoneEl.appendChild(hints);

    // hint lines
    this.hints = [
      new Hint(dw / 2, dw / 2, 0, 0),
      new Hint(Math.sqrt(2 * Math.pow(dw / 2, 2)) / 2, dw / 4, dw / 4, -45),
      new Hint(Math.sqrt(2 * Math.pow(dw / 2, 2)) / 2, dw / 4 * 3, dw / 4, 45),
      new Hint(dw / 2, 0, dw / 2, -90),
      new Hint(dw / 4, dw / 4, dw / 2, 0),
      new Hint(dw / 4, dw / 2, dw / 2, 0),
      new Hint(Math.sqrt(2 * Math.pow(dw / 2, 2)) / 2, dw / 2, dw / 2, -45),
      new Hint(Math.sqrt(2 * Math.pow(dw / 2, 2)) / 2, dw, dw / 2, 45),
      new Hint(dw / 2, dw / 4, dw / 4 * 3, -90),
    ];

    this.hints.forEach(h => {
      const el = h.createElement();
      hints.append(el);
    });

  }

  _initPickupZone() {

    const zoneEl = this.pickupZoneEl;

    // Clear zone
    while(zoneEl.firstChild) {
      zoneEl.removeChild(zoneEl.lastChild);
    }

    // Init pieces locations
    const transformations = {
      'sl': new Transformation(180, 70, -45),
      'sm': new Transformation(0, 140, 0),
      'tl1': new Transformation(0, 110, 225),
      'tl2': new Transformation(265, 190, 135),
      'tm1': new Transformation(155, 189, 180),
      'tm2': new Transformation(280, 175, 180),
      'ts1': new Transformation(45, 100, 315),
      'ts2': new Transformation(110, 188, 135),
      'p': new Transformation(85, 20, 0),
    };

    this.pieces.forEach(piece => {
      const el = piece.createElement();
      zoneEl.append(el);
      if (transformations[piece.name]) {
        piece.transform(transformations[piece.name]);
      }

      // add on drag events
      el.addEventListener('touchstart', e => {
        if (!this.disabled) {
          this.draggedPiece = piece;
          piece.dragStart(e);
        }
      });
      el.addEventListener('mousedown', e => {
        if (!this.disabled && (e.button === 0 || e.button === undefined)) {
          this.draggedPiece = piece;
          piece.dragStart(e);
        }
      });

    });
  }

  _onDocumentMouseMove(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.draggedPiece) {
      this.lastX = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX;
      this.lastY = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY;
      this.draggedPiece.drag(e);
    }
  }

  _onDocumentMouseUp() {
    if (this.draggedPiece) {

      // we check if it's dropping on top of any drop zone pieces
      let dropped = false;
      this.drops.forEach(drop => {
        if (drop.isDropped(this.lastX, this.lastY, this.draggedPiece)) {
          dropped = true;
          drop.colorize();
          this.draggedPiece.destroy();
        }
      });

      if (dropped) {
        this.pieces.splice(this.pieces.indexOf(this.draggedPiece), 1);
      } else {
        this.draggedPiece.dragEnd();
      }

      if (this.pieces.length === 0) {
        this.runComplete();
      }

      this.draggedPiece = null;
    }
  }

  runComplete() {
    this.onComplete();
  }

  enable(enable = true) {
    this.disabled = !enable;
    if (enable) this.pickupZoneEl.classList.remove('disabled');
    else this.pickupZoneEl.classList.add('disabled');
  }

  disable(disable = true) {
    this.disabled = disable;
    if (disable) this.pickupZoneEl.classList.add('disabled');
    else this.pickupZoneEl.classList.remove('disabled');
  }

  destroy() {
    document.removeEventListener('touchend', this._onDocumentMouseUp);
    document.removeEventListener('mouseup', this._onDocumentMouseUp);
    document.removeEventListener('touchmove', this._onDocumentMouseMove);
    document.removeEventListener('mousemove', this._onDocumentMouseMove);
    this.pieces.forEach(p => p.destroy());
    this.drops.forEach(p => p.destroy());
    this.pieces = [];
    this.drops = [];
  }
}

export default Tangram;
