import CastScreen from "./CastScreen";
import './JumpCastScreen.scss';
import {useEffect, useMemo, useRef, useState} from "react";
import Goal from "./components/Goal";
import Sprite from "../../components/Sprite";

import Platform from '../../assets/game3-platform.svg';
import Idle1 from '../../assets/game3-idle1.svg';
import Idle2 from '../../assets/game3-idle2.svg';
import Idle3 from '../../assets/game3-idle3.svg';
import Jump1 from '../../assets/game3-jump1.svg';
import Jump2 from '../../assets/game3-jump2.svg';
import Jump3 from '../../assets/game3-jump3.svg';
import Win2 from '../../assets/game3-win2.svg';
import Win3 from '../../assets/game3-win3.svg';

import StageNull from '../../assets/game3-stage-null.svg';
import StagePass from '../../assets/game3-stage-pass.svg';
import StageFail from '../../assets/game3-stage-fail.svg';

import confettiModule from "canvas-confetti";

const MAX_STAGES = 3;

function JumpCastScreen ({config}) {

  const [results, setResults] = useState({});
  const [stage, setStage] = useState(1);
  const jumpRef = useRef();
  const [value, setValue] = useState(7);
  const [error, setError] = useState(0.5);
  const [target, setTarget] = useState(7);
  const [end, setEnd] = useState(false);
  const [mode, setMode] = useState(null);
  const [vw, setVw] = useState(window.innerWidth);
  const [endingLeft, setEndingLeft] = useState(0);
  const [endingTop, setEndingTop] = useState(0);

  const [loadedAt] = useState((new Date()).toISOString());
  const [fireworksAt, setFireworksAt] = useState(null);

  const figureSize = vw * 0.1458;
  const platformLeft = vw * 0.775;
  const platformCenter = vw * 0.85;
  const platformRight = vw * 0.92;

  // we use the target and error to find out where should we stand
  const perValueWidth = (platformCenter - platformLeft) / error;
  const perValueHeight = vw * 0.36 / target;
  const jumpWidth = value > target + error ? platformRight + 0.3 * vw :
      platformCenter - (target - value) * perValueWidth;
  const jumpHeight = perValueHeight * value;

  const won = Math.abs(target - value) <= error;

  useEffect(() => {
    setValue(config.value || 7);
    setTarget(Math.max(0, config.target || 7));
    setError(config.error || 0.05);
    setMode(config.mode || 'still');
    setStage(config.stage || 1);
    setEnd(false);
    setResults(config.stages || {});
    setFireworksAt(config.fireworks_at || null);
  }, [config]);

  useEffect(() => {
    const onResize = () => {
      setVw(window.innerWidth);
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  useEffect(() => {
    let intv;
    if (mode === 'jump') {
      intv = setTimeout(() => {
        const rect = jumpRef.current.getBoundingClientRect();
        setEndingLeft(rect.x);
        setEndingTop(rect.y);
        setEnd(true);

        if (won) {
          setTimeout(() => {
            confettiModule({
              particleCount: 130,
              spread: 40,
              origin: { x: 0.85, y: 0.85 }
            });
          }, 400);
        }
      }, 3500);
    }
    return () => {
      clearInterval(intv);
    }
  }, [won, mode]);

  useEffect(() => {
    if (fireworksAt > loadedAt) {
      setEnd(true);
      confettiModule({
        particleCount: 130,
        spread: 40,
        origin: { x: 0.85, y: 0.85 }
      });
    }
  }, [loadedAt, fireworksAt]);

  return <CastScreen className={"JumpCastScreen"}>
    <Goal>
      Hit the button at {target || 7} secs!
    </Goal>

    <div className={"average"}>
      <div className={"label"}>AVG SECS:</div>
      {end && <div className={"value"}>{Math.trunc(value * 100) / 100}s</div>}
      {!end && <div className={"value"}>??? s</div>}
      {end && <div className={"stages"}>
        {[...Array(MAX_STAGES).keys()].map(m =>
          <div className={"stage"} key={m}>
            {stage > m ? <img src={results['s' + (m + 1)] === 'Success' ? StagePass : StageFail} alt={"stage"} /> :
              <img src={StageNull} alt={"stage"} />}
          </div>
        )}
      </div>}
      {!end && <div className={"stages"}>
        {[...Array(MAX_STAGES).keys()].map(m =>
          <div className={"stage"} key={m}>
            {stage > m + 1 ? <img src={results['s' + (m + 1)] === 'Success' ? StagePass : StageFail} alt={"stage"} /> :
              <img src={StageNull} alt={"stage"} />}
          </div>
        )}
      </div>}
    </div>

    <div className={"zone"}>

      {end && won ? <div className={"end"} style={{left: endingLeft, top: endingTop}}>
        <Sprite images={[Win2, Win3]} fps={2} width={figureSize} height={figureSize}/>
      </div> : <>
        {mode === 'still' && <div className={"idle"}>
          <Sprite images={[Idle1, Idle2, Idle3]} fps={4} width={figureSize} height={figureSize} />
        </div>}
        {mode === 'jump' && <div className={"jump" + (!won ? ' overshoot' : '')} style={{width: jumpWidth, height: jumpHeight}} >
          <div className={"x-axis"}>
            <div className={"y-axis"}>
              <Sprite images={[Jump1, Jump2, Jump3]} fps={4} loop={false} width={figureSize} height={figureSize} ref={jumpRef} />
            </div>
          </div>
        </div>}
      </>}

      <div className={"platform left"}>
        <img src={Platform} alt={"platform"} />
      </div>

      <div className={"platform right"}>
        <img src={Platform} alt={"platform"} />
      </div>
    </div>
  </CastScreen>;
}

export default JumpCastScreen;
