let zIndex = 10;
class Piece {

  name = 'piece';

  constructor(boardWidth = 50, name = 'piece', color = '') {
    this.boardWidth = boardWidth;
    this.name = name;
    this.color = color;
  }

  clone() {
    return new this.constructor(this.boardWidth, this.name, this.color);
  }

  _createElement() {
    return document.createElement('div');
  }

  createElement() {
    const el = this._createElement();
    el.classList.add('piece');
    return this.el = el;
  }

  plain() {

  }

  colorize() {

  }

  dragStart(e) {
    this.el.classList.add('dragging');
    this.el.style.zIndex = ++zIndex;
    this.startLeft = this.el.offsetLeft;
    this.startTop = this.el.offsetTop;
    this.offsetLeft = (e.touches && e.touches.length ? e.touches[0].clientX : e.clientX) - this.startLeft;
    this.offsetTop = (e.touches && e.touches.length ? e.touches[0].clientY : e.clientY) - this.startTop;
  }

  drag(e) {
    this.el.style.left = ((e.touches && e.touches.length ? e.touches[0].clientX : e.clientX) - this.offsetLeft) + 'px';
    this.el.style.top = ((e.touches && e.touches.length ? e.touches[0].clientY : e.clientY) - this.offsetTop) + 'px';
  }

  dragEnd() {
    this.el.classList.add('snap');
    this.el.classList.remove('dragging');
    this.el.style.left = this.startLeft + 'px';
    this.el.style.top = this.startTop + 'px';
    setTimeout(() => {
      this.el.classList.remove('snap');
    }, 100);
    delete this.offsetLeft;
    delete this.offsetTop;
  }

  isDropped(x, y, piece) {
    const rect = this.el.getBoundingClientRect();
    return x > rect.left && x < rect.right &&
      y > rect.top && y < rect.bottom &&
      this.name === piece.name;
  }

  transform(t) {
    this.el.style.left = t.x + 'px';
    this.el.style.top = t.y + 'px';
    this.el.style.transform = `rotate(${t.rotate}deg)`;
  }

  destroy() {
    this.el.parentElement.removeChild(this.el);
  }
}

export default Piece;