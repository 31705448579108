import Config from "./Config";
import CastButton from "./components/CastButton";
import Section from "../Section";
import ResetGameButton from "./components/ResetGameButton";
import {getDatabase, ref, update, onValue, query, orderByChild} from "firebase/database";
import {downloadCSV} from "./util";
import {useState} from "react";

function HoldingScreenConfig({onCast, casting}) {

  const [exportedRows, setExportedRows] = useState(null);
  const [exporting, setExporting] = useState(false);

  const handleCast = () => {
    onCast('', null, null);
  }

  const handleReset = () => {
    const db = getDatabase();
    update(ref(db), {
      'cast': null,
      'controller': null,
      'players': null,
      'player': null,
      'stats': null,
    });
  };

  const handleDownloadReport = () => {
    downloadCSV(exportedRows, 'players.csv');
    setExportedRows(null);
  };

  const handleGenerateReport = () => {
    const rows = [
      ["ID", "Name", "Last Accessed At", "G1: Tangram (Solve Speed)", /*"G2: Run (Total Clicks)",*/ "G3: Jump (Avg)"],
    ];

    const db = getDatabase();
    setExporting(true);

    const r = query(ref(db, 'players'), orderByChild('last_accessed_at'));

    onValue(r, snapshot => {
      snapshot.forEach(child => {
        const c = child.val();
        let jumpTotalScore = 0;
        let jumps = 0;
        for(let k in c.jump) {
          if (c.jump.hasOwnProperty(k) && c.jump[k].score > 0) {
            jumpTotalScore += c.jump[k].score;
            ++jumps;
          }
        }
        rows.push([
          child.val().id, child.val().name, c.last_accessed_at, c.tangram?.score || '', /* c.run?.score || '', */ jumpTotalScore > 0 ? (jumpTotalScore / jumps).toFixed(3) : '',
        ])
        setExportedRows(rows);
      })
      setExporting(false);
    }, {
      onlyOnce: true
    });
  };

  return <Config>
    <CastButton onClick={handleCast} casting={casting} disabled={casting} />
    <Section>
      <p style={{fontSize: '0.9em'}}>
        {casting ? <>
          Holding screen is on the screen now and you cannot un-cast it because you can't 😏
         </> : <>
          There is no configuration for holding screen.
          Simply tap on the <strong>Cast Now</strong> button to cast the holding
            screen now.
        </>}
      </p>
      <p>
        {exporting ? <span>Generating...</span> :
          exportedRows ? <button className={"btn btn-text"} onClick={handleDownloadReport}>Download Report</button> :
            <button className={"btn btn-text"} onClick={handleGenerateReport}>Generate Report</button>
        }
      </p>
    </Section>
    <ResetGameButton
      label={"Reset System"}
      confirmMessage={"RESET WHOLE GAME SYSTEM? This will reset all player data including play histories."}
      doubleConfirmQuestion={'Type "reset system" to confirm'}
      doubleConfirmAnswer={"reset system"}
      successMessage={"System has been reset!"}
      onReset={handleReset}
    />
  </Config>;
}

export default HoldingScreenConfig;
