import './BigButton.scss';
import {useEffect, useState} from "react";

function BigButton({image, depressedImage, onClick = () => {}, disabled = false}) {
  const [depressed, setDepressed] = useState(false);

  const handleClick = e => {
    if (!disabled) {
      onClick();
    }
  }

  const handleMouseDown = e => {
    if (!disabled && (e.button === 0 || e.button === undefined)) {
      setDepressed(true);
    }
  }

  const handleMouseUp = () => {
    setDepressed(false);
  }

  useEffect(() => {
    const handle = () => {
      setDepressed(false);
    }
    document.addEventListener('touchend', handle);
    document.addEventListener('mouseup', handle);
    return () => {
      document.removeEventListener('touchend', handle);
      document.removeEventListener('mouseup', handle);
    }
  }, []);

  return <div className={"BigButton" + (disabled ? ' disabled' : '')}
              draggable={false}
              onClick={handleClick}

              onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}
              onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp}>
    {depressed ? <img src={depressedImage} draggable={false} /> : <img src={image} draggable={false}/>}
  </div>
}

export default BigButton;
