import CastScreen from "./CastScreen";
import './TangramCastScreen.scss';
import {useEffect, useState} from "react";
import Pinwheel from '../../assets/game1-pinwheelc-top.svg';
import Stick from '../../assets/game1-pinwheelc-stick.svg';
import Goal from "./components/Goal";
import confettiModule from "canvas-confetti";

function TangramCastScreen ({config}) {

  const [value, setValue] = useState(0);
  const [vw, setVw] = useState(window.innerWidth);


  useEffect(() => {
    setValue(config.value || 0);

    if (config.value >= 100) {
      setTimeout(() => {
        confettiModule({
          particleCount: 130,
          spread: 40,
          origin: { x: 0.78, y: 0.95 }
        });
      }, 200);
    }
  }, [config]);

  useEffect(() => {
    const onResize = () => {
      setVw(window.innerWidth);
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  // The bottom of the percentage bar is between 1.7vw -> 31vw
  const percentageBottomPer = 1.7;
  const percentageTopPer = 31;
  const percentageBottom = (percentageBottomPer + (percentageTopPer - percentageBottomPer) * (value / 100)) / 100 * vw;

  // The percentage growth on pinwheel is between 5.7% -> 94.3%
  const pinwheelFrom = 5.7;
  const pinwheelTo = 94.3;
  const pinwheelPercentage = pinwheelFrom + (pinwheelTo - pinwheelFrom) * (value / 100);

  return <CastScreen className={"TangramCastScreen" + (value === 100 ? ' completed' : '')}>
    <Goal colorFrom={"#D80079"} colorTo={"#7E0F78"}>
      80% of audience success
    </Goal>
    <div className={"percentage"}>
      <div className={"move"} style={{bottom: percentageBottom + 'px'}}>
        <div className={"label"}>{Math.ceil(value)}%</div>
        <div className={"line"} />
      </div>
    </div>
    <div className={"pinwheel foreground"}>
      {/**/}
      <div className={"pin"} style={{clipPath: `polygon(0% ${100 - pinwheelPercentage}%, 100% ${100 - pinwheelPercentage}%, 100% 100%, 0 100%)`}}>
        <img src={Pinwheel} alt={"Pinwheel"} />
      </div>
    </div>
    <div className={"pinwheel background"}>
      <div className={"pin"}>
        <img src={Pinwheel} alt={"Pinwheel"}  />
      </div>
      <div className={"stick"}>
        <img src={Stick} alt={"Stalk"} />
      </div>
    </div>
  </CastScreen>;
}

export default TangramCastScreen;
