/**
 * 1. Create firebase.js from this file
 * 2. Go to the Firebase Console (https://console.firebase.google.com/)
 * 3. Create or find a project
 * 4. Create a Web Project or find its Web Project (Project Settings)
 * 5. Copy the firebaseConfig and paste it here. JUST the firebaseConfig object.
 */
const firebaseConfig = {
  apiKey: "AIzaSyAEeQ85_ncieqsvobO1KvLQC1WmWQETHus",
  authDomain: "pinwheel-6e8a6.firebaseapp.com",
  databaseURL: "",
  projectId: "pinwheel-6e8a6",
  storageBucket: "pinwheel-6e8a6.appspot.com",
  messagingSenderId: "372675128030",
  appId: "1:372675128030:web:7f17630dd253cb42efb04f",
  measurementId: "G-QVJQEE353Z"
};

// Overwrites database
firebaseConfig.databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export default firebaseConfig;
