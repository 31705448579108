import Section from "../../Section";
import './Settings.scss';

function Settings({children, header = "Settings"}) {
  return <Section header={header} className={"Settings"}>
    {children}
  </Section>;
}

export default Settings;
