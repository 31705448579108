import './Goal.scss';

function Goal({children, colorFrom, colorTo}) {
  return <div className={"Goal"}>
    <div className={"title"} style={{
      background: `linear-gradient(90deg, ${colorFrom} 0%, ${colorTo} 100%)`
    }}>
      {children}
    </div>
  </div>
}

export default Goal;
