import Piece from "../Piece";

class Square extends Piece {
  _calculateWidth() {
    return 70;
  }

  _createElement() {
    const w = this._calculateWidth();
    const el = document.createElement('div');
    el.classList.add('tg-square');
    el.style.width = w + 'px';
    el.style.height = w + 'px';
    el.style.backgroundColor = this.color;
    return el;
  }

  plain() {
    this.el.style.backgroundColor = 'white';
  }

  colorize() {
    this.el.style.backgroundColor = this.color;
  }
}

export default Square;