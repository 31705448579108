import './Lightning.scss';
import {useEffect, useRef} from "react";

/**
 * Src: https://codepen.io/mcdorli/post/creating-lightnings-with-javascript-and-html5-canvas
 */
const LightningBolt = () => {

  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const {width, height} = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d');

    // Resize the canvas for better resolution
    const { devicePixelRatio:ratio=1 } = window;
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    ctx.scale(ratio, ratio);

    const center = {x: width / 2, y: 0};
    const minSegmentHeight = 10;
    const groundHeight = height;
    const color = 'white';
    const roughness = 2;
    const maxDifference = width / 4;

    ctx.globalCompositeOperation = "lighter";
    ctx.strokeStyle = color;

    const createLightnings = () => {
      let segmentHeight = groundHeight - center.y;
      let lightnings = [];
      lightnings.push({x: center.x, y: center.y});
      lightnings.push({x: Math.random() * (width - 10) + 50, y: groundHeight + (Math.random() - 0.9) * 100});
      let currDiff = maxDifference;
      while (segmentHeight > minSegmentHeight) {
        const newSegments = [];
        for (let i = 0; i < lightnings.length - 1; i++) {
          const start = lightnings[i];
          const end = lightnings[i + 1];
          const midX = (start.x + end.x) / 2;
          const newX = midX + (Math.random() * 2 - 1) * currDiff;
          newSegments.push(start, {x: newX, y: (start.y + end.y) / 2});
        }

        newSegments.push(lightnings.pop());
        lightnings = newSegments;

        currDiff /= roughness;
        segmentHeight /= 2;
      }
      return lightnings;
    };

    ctx.shadowBlur = 0;
    ctx.lineWidth = 7;
    ctx.clearRect(0, 0, width, height);
    for (let i = 0 ; i < 5 ; ++i) {
      ctx.beginPath();
      createLightnings().forEach(l => ctx.lineTo(l.x, l.y));
      ctx.stroke();
    }

  }, [canvasRef]);

  return <div className={"LightningBolt"}>
    <canvas ref={canvasRef} />
  </div>

};

export default LightningBolt;
