import './ControlPanel.scss';
import GameSelector from "./GameSelector";
import TangramConfig from "./config/TangramConfig";
import HoldingScreenConfig from "./config/HoldingScreenConfig";
import RunConfig from "./config/RunConfig";
import JumpConfig from "./config/JumpConfig";
function ControlPanel ({game, onGame, onCast, castingGame}) {

  return <div className={"ControlPanel"}>
    <GameSelector game={game} onGame={onGame} disabled={castingGame !== ''} />
    {game === '' && <HoldingScreenConfig onCast={onCast} casting={castingGame === ''} />}
    {game === 'tangram' && <TangramConfig onCast={onCast} casting={castingGame === 'tangram'} />}
    {game === 'run' && <RunConfig onCast={onCast} casting={castingGame === 'run'} />}
    {game === 'jump' && <JumpConfig onCast={onCast} casting={castingGame === 'jump'} />}
  </div>
}

export default ControlPanel;
