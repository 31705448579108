import Config from "./Config";
import CastButton from "./components/CastButton";
import ResetGameButton from "./components/ResetGameButton";
import Settings from "./components/Settings";
import ProgressBar from "./components/ProgressBar";
import Section from "../Section";
import {useEffect, useState} from "react";
import {getDatabase, onValue, ref, set, update} from "firebase/database";
import {addComma} from "../../../common";
import Statistics from "./components/Statistics";
import EndPlayerCast from "./components/EndPlayerCast";

function RunConfig({onCast, casting}) {

  const [timerStarted, setTimerStarted] = useState(false);
  const [duration, setDuration] = useState(90);
  const [target, setTarget] = useState(6000);

  const [actualValue, setActualValue] = useState(0);
  const [adminValue, setAdminValue] = useState(0);

  const [tempDuration, setTempDuration] = useState(duration || 0);
  const [tempTarget, setTempTarget] = useState(target || 0);

  const [totalPlayers, setTotalPlayers] = useState(0);
  const [playersStarted, setPlayersStarted] = useState(0);
  const [playersClicks, setPlayersClicked] = useState(0);

  const safeValue = Math.min(100, Math.max(0, Math.max(actualValue, adminValue)));

  useEffect(() => {
    const db = getDatabase();
    const unsubscribePlayersStats = onValue(ref(db, 'stats/run'), snapshot => {
      const val = snapshot.val() || {};
      setPlayersStarted(val.players_started || 0);
      setPlayersClicked(val.players_clicked || 0);
      setActualValue((val.players_clicked || 0) / target * 100);
    });
    const unsubscribeTotalPlayers = onValue(ref(db, 'total_players'), snapshot => {
      setTotalPlayers(snapshot.val() || 0);
    });
    return () => {
      unsubscribePlayersStats();
      unsubscribeTotalPlayers();
    };
  }, [target]);

  useEffect(() => {
    // listening to the config value
    const db = getDatabase();
    const unsubscribe = onValue(ref(db, 'controller/run'), snapshot => {
      const config = snapshot.val() || {};
      setDuration(config.duration || 90);
      setTempDuration(config.duration || 90);
      setTarget(config.target || 6000);
      setTempTarget(config.target || 6000);
      setAdminValue(config.admin_value || 0);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const updates = {};
    updates['/cast/run/value'] = safeValue;
    update(ref(db), updates);
  }, [safeValue, actualValue, adminValue]);

  const handleCast = () => {
    onCast('run', {
      value: safeValue,
    }, {
      ended_at: null
    });
  }

  const handleReset = () => {
    setTimerStarted(false);
    const db = getDatabase();

    const updates = {};
    updates['cast/run'] = {
      value: 0
    };
    updates['controller/run'] = null;
    updates['player/run'] = {
      reset_at: (new Date()).toISOString(),
    };
    updates['stats/run'] = null;
    update(ref(db), updates);

    // Reset players
    onValue(ref(db, 'players'), snapshot => {
      const updates = {};
      snapshot.forEach(playerSnapshot => {
        updates[`players/${playerSnapshot.key}/run`] = null;
      });
      update(ref(db), updates);
    }, {
      onlyOnce: true
    });
  }

  const handleDurationChange = e => {
    setTempDuration(e.target.value);
  };

  const handleDurationBlur = e => {
    setTempDuration(parseInt(e.target.value) || 0);
  }

  const handleTargetChange = e => {
    setTempTarget(e.target.value);
  };

  const handleTargetBlur = e => {
    setTempTarget(parseInt(e.target.value) || 0);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSaveSettings();
    }
  }

  const handleSaveSettings = () => {
    const db = getDatabase();
    update(ref(db), {
      'controller/run': {
        started: timerStarted,
        duration: parseInt(tempDuration),
        target: parseInt(tempTarget),
        admin_value: adminValue
      },
      'cast/run/value': Math.max(adminValue, actualValue),
    });
  };

  const handleCancelSettings = () => {
    setTempTarget(target);
    setTempDuration(duration);
  };

  const handleTimerStart = () => {
    setTimerStarted(true);
  };

  const handleTimerStop = () => {
    setTimerStarted(false);
  }

  const handleAdminValue = v => {
    const db = getDatabase();
    setAdminValue(v);
    update(ref(db), {
      'controller/run/admin_value': v,
      'cast/run/value': Math.max(v, actualValue)
    });
  }

  const handleEndPlayer = () => {
    if (safeValue === 100) {
      const db = getDatabase();
      set(ref(db, '/player/run/ended_at'), (new Date()).toISOString());
    }
  }

  const handleEndCast = () => {
    if (safeValue === 100) {
      const db = getDatabase();
      set(ref(db, '/cast/run/last_lightning'), (new Date()).toISOString());
    }
  }

  const settingsDirty = tempDuration !== duration ||
    tempTarget !== target;

  return <Config>
    <CastButton onClick={handleCast} casting={casting} />

    <Statistics>
      <div className={"row"}>
        <div className={"lbl"}>Total Players</div>
        <div className={"val"}>{addComma(totalPlayers)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Players Started</div>
        <div className={"val"}>{addComma(playersStarted)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Total Clicks</div>
        <div className={"val"}>{addComma(playersClicks)}</div>
      </div>
      {/*<div className={"row"}>*/}
      {/*  <div className={"lbl"}>Average Completion Time</div>*/}
      {/*  <div className={"val"}>{(playersAverage).toFixed(2)}s</div>*/}
      {/*</div>*/}
    </Statistics>

    <Settings>
      <div className={"form-group"}>
        <label>Timer Duration (in Seconds)</label>
        <input type={"number"} className={"form-control"} value={tempDuration} onChange={handleDurationChange}  onBlur={handleDurationBlur} onKeyDown={handleKeyDown} />
      </div>
      <div className={"form-group"}>
        <label>Goal (# of Clicks)</label>
        <input type={"number"} className={"form-control"} value={tempTarget} onChange={handleTargetChange}  onBlur={handleTargetBlur} onKeyDown={handleKeyDown} />
        <div className={"help-text"}>Average CPS is 7, which translates to around {duration * 7} clicks for a {duration} seconds time period.</div>
      </div>
      <div className={"actions"}>
        <button className={"btn btn-primary"} disabled={!settingsDirty} onClick={handleSaveSettings}>Save Settings</button>
        {settingsDirty && <button className={"btn btn-text"} onClick={handleCancelSettings}>Cancel</button>}
      </div>
    </Settings>

    <Section header={"Progress"}>
      <ProgressBar
        started={timerStarted}
        value={actualValue}
        duration={duration}
        onStart={handleTimerStart}
        onStop={handleTimerStop}
        adminValue={adminValue}
        onAdminValue={handleAdminValue}
      />
    </Section>

    <EndPlayerCast
      endCastDisabled={safeValue < 100}
      endPlayerDisabled={safeValue < 100}
      onEndCast={handleEndCast}
      onEndPlayer={handleEndPlayer}
    />
    <ResetGameButton onReset={handleReset} />
  </Config>
}

export default RunConfig;
