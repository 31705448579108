import './Cast.scss';
import {useEffect, useState} from "react";
import {getDatabase, onValue, ref} from "firebase/database";
import HoldingCastScreen from "./cast/HoldingCastScreen";
import TangramCastScreen from "./cast/TangramCastScreen";
import RunCastScreen from "./cast/RunCastScreen";
import JumpCastScreen from "./cast/JumpCastScreen";
export default function Cast() {

  const [game, setGame] = useState(null);
  const [config, setConfig] = useState({});

  useEffect(() => {
    const db = getDatabase();

    const unsubscribe = onValue(ref(db, 'cast'), snapshot => {
      const cast = snapshot.val() || {};
      const game = cast.game || '';
      setGame(game);
      setConfig(cast[game] || {});
    });

    return () => {
      unsubscribe();
    };
  }, []);


  return <div className={"Cast"}>
    {game === '' && <HoldingCastScreen config={config} />}
    {game === 'tangram' && <TangramCastScreen config={config} />}
    {game === 'run' && <RunCastScreen config={config} />}
    {game === 'jump' && <JumpCastScreen config={config} />}
  </div>;
};
