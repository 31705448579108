export const games = [{
  name: 'tangram',
  label: 'Game 1: Tangram'
}
,{
  name: 'run',
  label: 'Game 2: Run'
}
,{
  name: 'jump',
  label: 'Game 3: Jump'
}];
