import './Participant.scss';
import {
  getAuth,
  signInAnonymously,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import {useContext, useEffect, useRef, useState} from "react";
import Loader from "../components/Loader";
import queryString from "query-string";
import {getDatabase, onValue, ref, set, update} from "firebase/database";
import HoldingPlayerScreen from "./player/HoldingPlayerScreen";
import TangramPlayerScreen from "./player/TangramPlayerScreen";
import {UserContext} from "../Contexts";
import RunPlayerScreen from "./player/RunPlayerScreen";
import JumpPlayerScreen from "./player/JumpPlayerScreen";

const qs = queryString.parse(window.location.search);

export default function Participant() {

  const root = useRef();
  const {user} = useContext(UserContext);
  const [userLoaded, setUserLoaded] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [game, setGame] = useState('');
  const [config, setConfig] = useState({});

  useEffect(() => {
    // if we do not have a user, attempt to do anonymous sign in
    if (!user) {
      const auth = getAuth();
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          signInAnonymously(auth)
            .then(() => {
            })
            .catch(err => {
              alert('Could not initialize. Please check your internet connection or refresh this page.');
            });
        })
        .catch(err => {
          alert('Failed to initialise persistence storage');
        });
    } else {
      const db = getDatabase();
      const updates = {};
      updates[`/players/${user.uid}/last_accessed_at`] = (new Date()).toISOString();
      if (qs.name) updates[`/players/${user.uid}/name`] = qs.name;
      if (qs.id) updates[`/players/${user.uid}/id`] = qs.id;
      update(ref(db), updates);
      setUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    const db = getDatabase();

    const unsubscribe = onValue(ref(db, 'player'), snapshot => {
      const player = snapshot.val() || {};
      const game = player.game || '';
      setGame(game);
      setConfig(player[game] || {});
      setConfigLoaded(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleStart = (game, startedAt, extra = {}) => {
    const db = getDatabase();
    const updates = {};
    updates[`/players/${user.uid}/${game}/started_at`] = startedAt.toISOString();
    update(ref(db), {...updates, ...extra});
  };

  const handleComplete = (game, completedAt, score, extra = {}) => {
    const db = getDatabase();
    const updates = {};
    updates[`/players/${user.uid}/${game}/completed_at`] = completedAt.toISOString();
    updates[`/players/${user.uid}/${game}/score`] = score
    update(ref(db), {...updates, ...extra});
  };

  return !configLoaded || !userLoaded ? <Loader /> : <div className={"Participant"} ref={root}>
    {game === '' && <HoldingPlayerScreen config={config} user={user} name={qs.name} />}
    {game === 'tangram' && <TangramPlayerScreen config={config} user={user} onStart={handleStart} onComplete={handleComplete} />}
    {game === 'run' && <RunPlayerScreen config={config} user={user} onStart={handleStart} onComplete={handleComplete} />}
    {game === 'jump' && <JumpPlayerScreen config={config} user={user} onStart={handleStart} onComplete={handleComplete} />}
  </div>;
};
