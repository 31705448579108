import PlayerScreen from "./PlayerScreen";
import './tangram/tangram.scss';
import "./TangramPlayerScreen.scss";
import {useEffect, useRef, useState} from "react";
import Tangram from "./tangram/Tangram";
import dayjs from "dayjs";
import StartOverlay from "./components/StartOverlay";
import confettiModule from "canvas-confetti";
import Instruction from "./components/Instruction";

function TangramPlayerScreen ({config, user, onStart, onComplete}) {

  const pickupZoneRef = useRef();
  const dropZoneRef = useRef();
  const boardRef = useRef();

  const [loadedAt] = useState((new Date()).toISOString());
  const [resetAt, setResetAt] = useState(null);

  const [tg, setTg] = useState(null);
  const [duration, setDuration] = useState(config.duration || 45);
  const [startedAt, setStartedAt] = useState(null);
  const [completedAt, setCompletedAt] = useState(null);
  const [failed, setFailed] = useState(false);
  const [remaining, setRemaining] = useState((config.duration || 45) * 1000);

  useEffect(() => {
    setResetAt(config.reset_at || null);
  }, [config]);

  useEffect(() => {
    if (resetAt > loadedAt) {
      window.location.reload();
    }
  }, [loadedAt, resetAt]);

  useEffect(() => {
    let intv;
    if (startedAt) {
      intv = setInterval(() => {
        if (startedAt && completedAt === null) {
          setRemaining(Math.max(0, duration * 1000 - dayjs().valueOf() + startedAt.valueOf()));
        }
      }, 50);
    }
    return () => {
      clearInterval(intv);
    };
  }, [completedAt, startedAt, duration]);

  useEffect(() => {
    setDuration(config.duration || 45);
    if (!startedAt) {
      setRemaining((config.duration || 45) * 1000);
    }
  }, [startedAt, config]);

  useEffect(() => {
    const t = new Tangram(boardRef.current, pickupZoneRef.current, dropZoneRef.current, () => {
      setCompletedAt(dayjs());
    });
    setTg(t);
    return () => {
      t.destroy();
    };
  }, [pickupZoneRef, dropZoneRef, boardRef]);

  useEffect(() => {
    if (completedAt) {
      setTimeout(() => {
        confettiModule({
          particleCount: 130,
          spread: 40,
          origin: { y: 0.7 }
        });
      }, 400);
      onComplete('tangram', completedAt, (completedAt.valueOf() - startedAt.valueOf())/1000);
    }
  }, [onComplete, startedAt, completedAt]);

  useEffect(() => {
    if (remaining <= 0 && !completedAt) {
      setFailed(true);
      tg.disable();
    }
  }, [tg, completedAt, remaining])

  const handleStart = () => {
    const start = dayjs();
    setStartedAt(start);
    tg.enable();
    onStart('tangram', start);
  };

  return <PlayerScreen className={"TangramPlayerScreen" +
    (failed ? ' failed' : '') +
    (completedAt ? ' completed' : '')}>

    <div className={"board"} ref={boardRef}>

      <div className={"up"}>
        <div className={"timer"}>
          <span className={"sec"}>{Math.floor(remaining / 1000).toString().padStart(2, '0')}</span>
          <span className={"msec"}>.{(remaining % 1000).toString().padStart(3, '0')}</span>
          <span className={"unit"}>s</span>
        </div>
        <div className={"drop-zone"} ref={dropZoneRef}>

        </div>
      </div>

      {failed && <Instruction>
        Time's Up!<br/>
      </Instruction>}

      {completedAt && <Instruction>
        Congratulations!<br/><br/>
        You have completed this game in {(completedAt.valueOf() - startedAt.valueOf())/1000}s!
      </Instruction>}

      {!failed && !completedAt && <Instruction>
        Solve the puzzle!<br/>
        Drag the pieces into the correct spot.
      </Instruction>}

      <div className={"down"}>
        {!startedAt && <StartOverlay onStart={handleStart} />}
        <div className={"pickup-zone"} ref={pickupZoneRef}>
        </div>
      </div>
    </div>
  </PlayerScreen>;
}

export default TangramPlayerScreen;
