import Config from "./Config";
import './TangramConfig.scss';
import ProgressBar from "./components/ProgressBar";
import Section from "../Section";
import {useEffect, useState} from "react";
import CastButton from "./components/CastButton";
import {getDatabase, onValue, ref, set, update} from "firebase/database";
import Statistics from "./components/Statistics";
import Settings from "./components/Settings";
import {addComma} from "../../../common";
import ResetGameButton from "./components/ResetGameButton";
import dayjs from "dayjs";
import EndPlayerCast from "./components/EndPlayerCast";

function TangramConfig({onCast, casting}) {

  const [, setCastValue] = useState(0);
  const [actualValue, setActualValue] = useState(0);
  const [adminValue, setAdminValue] = useState(0);
  const [playersCompleted, setPlayersCompleted] = useState(0);
  const [playersStarted, setPlayersStarted] = useState(0);
  const [fastestComplete, setFastestComplete] = useState(0);
  const [fastestName, setFastestName] = useState('');
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [started, setStarted] = useState(false);
  const [castDuration, setCastDuration] = useState(90);
  const [playerTimeLimit, setPlayerTimeLimit] = useState(45);
  const [target, setTarget] = useState(300);

  const [tempCastDuration, setTempCastDuration] = useState(90);
  const [tempPlayerTimeLimit, setTempPlayerTimeLimit] = useState(45);
  const [tempTarget, setTempTarget] = useState(300);

  const safeValue = Math.min(100, Math.max(0, Math.max(actualValue, adminValue)));

  useEffect(() => {
    const db = getDatabase();
    const unsubscribeCastConfig = onValue(ref(db, 'cast/tangram'), snapshot => {
      const cast = snapshot.val() || {};
      setCastValue(cast.value || 0);
    });
    const unsubscribePlayersStats = onValue(ref(db, 'stats/tangram'), snapshot => {
      const val = snapshot.val() || {};
      setPlayersCompleted(val.players_completed || 0);
      setPlayersStarted(val.players_started || 0);
      setFastestComplete(val.fastest_complete || 0);
      setFastestName(val.fastest_name);
      setActualValue((val.players_completed || 0) / target * 100);
    });
    const unsubscribeTotalPlayers = onValue(ref(db, 'total_players'), snapshot => {
      setTotalPlayers(snapshot.val() || 0);
    });
    return () => {
      unsubscribeCastConfig();
      unsubscribePlayersStats();
      unsubscribeTotalPlayers();
    };
  }, [target]);

  useEffect(() => {
    // listening to the config value
    const db = getDatabase();
    const unsubscribe = onValue(ref(db, 'controller/tangram'), snapshot => {
      const config = snapshot.val() || {};
      setCastDuration(config.cast_duration || 90);
      setTempCastDuration(config.cast_duration || 90);
      setPlayerTimeLimit(config.player_time_limit || 45);
      setTempPlayerTimeLimit(config.player_time_limit || 45);
      setTarget(config.target || 300);
      setTempTarget(config.target || 300);
      setAdminValue(config.admin_value || 0);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    set(ref(db, '/cast/tangram/value'), safeValue)
  }, [safeValue, actualValue, adminValue, playersCompleted]);

  const handleCast = () => {
    onCast('tangram', {
      value: safeValue
    }, {
      duration: playerTimeLimit
    });
  }

  const handleTimerStart = () => {
    setStarted(true);
  };

  const handleTimerStop = () => {
    if (window.confirm('Stop timer now? This will not change the game status and this action will merely stop and reset the timer.')) {
      setStarted(false);
    }
  };

  const handleCastDurationChange = e => {
    setTempCastDuration(e.target.value);
  };

  const handleCastDurationBlur = e => {
    setTempCastDuration(parseInt(e.target.value) || 0);
  };

  const handlePlayerTimeLimitChange = e => {
    setTempPlayerTimeLimit(e.target.value);
  };

  const handlePlayerTimeLimitBlur = e => {
    setTempPlayerTimeLimit(parseInt(e.target.value) || 0);
  };

  const handleTargetChange = e => {
    setTempTarget(e.target.value);
  }

  const handleTargetBlur = e => {
    setTempTarget(Math.max(playersCompleted, parseInt(e.target.value) || 0));
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSaveSettings();
    }
  }

  const handleSaveSettings = () => {
    const db = getDatabase();
    update(ref(db), {
      'controller/tangram': {
        cast_duration: parseInt(tempCastDuration),
        player_time_limit: parseInt(tempPlayerTimeLimit),
        target: parseInt(tempTarget), admin_value: adminValue
      },
      'cast/tangram/value': Math.max(adminValue, actualValue),
      'player/tangram/duration': tempPlayerTimeLimit
    });
  }

  const handleCancelSettings = () => {
    setTempCastDuration(castDuration);
    setTempPlayerTimeLimit(playerTimeLimit);
    setTempTarget(target);
  }

  const handleAdminValue = v => {
    const db = getDatabase();
    setAdminValue(v);
    update(ref(db), {
      'controller/tangram/admin_value': v,
      'cast/tangram/value': Math.max(v, actualValue)
    });
  }

  const handleReset = () => {
    setStarted(false);
    const db = getDatabase();

    const updates = {};
    updates['cast/tangram'] = {
      value: 0
    };
    updates['controller/tangram'] = null;
    updates['player/tangram'] = {
      reset_at: dayjs().toISOString(),
    };
    updates['stats/tangram'] = null;
    update(ref(db), updates);

    // Reset players
    onValue(ref(db, 'players'), snapshot => {
      const updates = {};
      snapshot.forEach(playerSnapshot => {
        updates[`players/${playerSnapshot.key}/tangram`] = null;
      });
      update(ref(db), updates);
    }, {
      onlyOnce: true
    });
  };

  const handleEndCast = () => {
    const db = getDatabase();
    update(ref(db), {
      'cast/tangram/fireworks_at': (new Date()).toISOString(),
    });
  };

  const settingsDirty = tempCastDuration !== castDuration ||
    tempPlayerTimeLimit !== playerTimeLimit ||
    tempTarget !== target;

  return <Config className={"TangramConfig"}>

    <CastButton onClick={handleCast} casting={casting} />

    <Statistics>
      <div className={"row"}>
        <div className={"lbl"}>Total Players</div>
        <div className={"val"}>{addComma(totalPlayers)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Players Started</div>
        <div className={"val"}>{addComma(playersStarted)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Players Completed</div>
        <div className={"val"}>{addComma(playersCompleted)}</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Fastest Completion Time</div>
        <div className={"val"}>{(fastestComplete).toFixed(2)}s</div>
      </div>
      <div className={"row"}>
        <div className={"lbl"}>Fastest Completion Name</div>
        <div className={"val"}>{fastestName ?? '?'}</div>
      </div>
    </Statistics>

    <Settings>
      <div className={"form-group"}>
        <label>Timer Duration (in Seconds)</label>
        <input type={"number"} className={"form-control"} value={tempCastDuration} onChange={handleCastDurationChange} onBlur={handleCastDurationBlur} onKeyDown={handleKeyDown} />
      </div>
      <div className={"form-group"}>
        <label>Player Time Limit (in Seconds)</label>
        <input type={"number"} className={"form-control"} value={tempPlayerTimeLimit} onChange={handlePlayerTimeLimitChange} onBlur={handlePlayerTimeLimitBlur} onKeyDown={handleKeyDown} />
      </div>
      <div className={"form-group"}>
        <label>Goal (# of Completions)</label>
        <input type={"number"} className={"form-control"} value={tempTarget} onChange={handleTargetChange} onBlur={handleTargetBlur} onKeyDown={handleKeyDown} />
        {tempTarget > target && <div className={"help-text text-danger"}>Increasing the target may decrease the % progress. Use with care.</div>}
      </div>
      <div className={"actions"}>
        <button className={"btn btn-primary"} disabled={!settingsDirty} onClick={handleSaveSettings}>Save Settings</button>
        {settingsDirty && <button className={"btn btn-text"} onClick={handleCancelSettings}>Cancel</button>}
      </div>
    </Settings>

    <Section header={"Progress"}>
      <ProgressBar
        started={started}
        value={actualValue}
        duration={castDuration}
        onStart={handleTimerStart}
        onStop={handleTimerStop}
        adminValue={adminValue}
        onAdminValue={handleAdminValue}
      />
    </Section>
    <EndPlayerCast
      endCastDisabled={safeValue < 100}
      hideEndPlayer={true}
      onEndCast={handleEndCast}
    />
    <ResetGameButton onReset={handleReset} />

  </Config>
}

export default TangramConfig;
