import './Loader.scss';
import LoaderPinwheel from '../assets/loader-pinwheel.png';
const Loader = () => <div className={"Loader"}>
  <div className={"pinwheel pinwheel-1"}>
    <img src={LoaderPinwheel} alt={"Pinwheel"} />
  </div>
  {/*<div className={"pinwheel pinwheel-2"}>*/}
  {/*  <img src={LoaderPinwheel} alt={"Pinwheel"} />*/}
  {/*</div>*/}
  {/*<div className={"pinwheel pinwheel-3"}>*/}
  {/*  <img src={LoaderPinwheel} alt={"Pinwheel"} />*/}
  {/*</div>*/}
  {/*<div className={"lbl"}>Getting ready...</div>*/}
</div>;
export default Loader;
