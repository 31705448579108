import Section from "../../Section";
import './Statistics.scss';

function Statistics({children}) {
  return <Section header={"Statistics"} className={"Statistics"}>
    {children}
  </Section>;
}

export default Statistics;
