import Piece from "../Piece";

class Triangle extends Piece {
  _calculateWidth() {
    return 70;
  }

  _createElement() {
    const el = document.createElement('div');
    el.classList.add('tg-triangle');
    el.style.borderWidth = this._calculateWidth() + 'px';
    el.style.borderLeftColor = this.color;
    return el;
  }

  plain() {
    this.el.style.borderLeftColor = 'white';
  }

  colorize() {
    this.el.style.borderLeftColor = this.color;
  }
}

export default Triangle;