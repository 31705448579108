import PlayerScreen from "./PlayerScreen";
import Logo from '../../assets/game-hs-audience-logo.svg';
import BadgeTangram from '../../assets/game-hs-badge1b.svg';
import BadgeTangramActive from '../../assets/game-hs-badge1c.svg';
import BadgeRun from '../../assets/game-hs-badge2b.svg';
import BadgeRunActive from '../../assets/game-hs-badge2c.svg';
import BadgeJump from '../../assets/game-hs-badge3b.svg';
import BadgeJumpActive from '../../assets/game-hs-badge3c.svg';
import './HoldingPlayerScreen.scss';
import {useEffect, useState} from "react";
import {getDatabase, ref, onValue} from "firebase/database";

const badges = [{
  name: 'tangram',
  badge: BadgeTangram,
  active: BadgeTangramActive,
}
,{
  name: 'run',
  badge: BadgeRun,
  active: BadgeRunActive,
}
,{
  name: 'jump',
  badge: BadgeJump,
  active: BadgeJumpActive,
}]

function HoldingPlayerScreen ({user, name}) {

  const [completedGames, setCompletedGames] = useState([]);


  useEffect(() => {
    let unsubscribe = null;
    if (user) {
      const db = getDatabase();
      unsubscribe = onValue(ref(db, `players/${user.uid}`), snapshot => {
        const player = snapshot.val();
        const completedGames = [];
        badges.forEach(b => {
          if (player[b.name] && player[b.name].started_at) {
            completedGames.push(b.name);
          }
        });
        setCompletedGames(completedGames);
      }, {
        onlyOnce: true
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    }
  }, [user]);

  return <PlayerScreen className={"HoldingPlayerScreen"}>
    <div className={"cover"}>
      <div className={"logo"}><img src={Logo} alt={"Logo"} /></div>
      <div className={"badges"}>
        {badges.map(b => <div className={"badge badge-" + b.name + ((completedGames.includes(b.name) ? ' active' : ''))} key={b.name}>
          <img src={completedGames.includes(b.name) ? b.active : b.badge} alt={"Badge"} />
        </div>)}
      </div>
      <div className={"footer"}>
        {name ? name : 'Participation Badges'}
      </div>
    </div>
  </PlayerScreen>;
}

export default HoldingPlayerScreen;
