import { useRef, useEffect } from 'react'

/**
 * https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
 */
const useCanvas = (draw, options = {}) => {

  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext(options.context || '2d')
    let frameCount = 0
    let animationFrameId

    const render = () => {
      frameCount++
      draw(context, canvas, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw, options.context])

  return canvasRef
}

export default useCanvas