import './NavBar.scss';
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../Contexts";
import dayjs from 'dayjs';
import {getDatabase, onValue, ref} from "firebase/database";
import {addComma} from "../../common";
import {signOut, getAuth} from 'firebase/auth';

const LS_TIME_FORMAT = 'pw-time-format';

function NavBar () {

  const {user, setUser} = useContext(UserContext);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [time, setTime] = useState('');
  const [timeFormat, setTimeFormat] = useState(window.localStorage.getItem(LS_TIME_FORMAT) || '12');


  useEffect(() => {
    const db = getDatabase();
    const unsubscribe = onValue(ref(db, 'total_players'), snapshot => {
      setTotalPlayers(snapshot.val() ?? 0);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const updateTime = () => {
      setTime(dayjs().format(timeFormat === '12' ? 'h:mm:ss A' : 'HH:mm:ss'));
    };
    const intv = setInterval(updateTime, 1000);
    updateTime();
    return () => {
      clearInterval(intv);
    }
  }, [timeFormat]);

  const handleTimeFormat = () => {
    const tf = timeFormat === '12' ? '24' : '12';
    setTimeFormat(tf);
    window.localStorage.setItem(LS_TIME_FORMAT, tf);
  }

  const handleSignOut = () => {
    if (window.confirm('Sign out now?')) {
      const auth = getAuth();
      signOut(auth).then(() => {
        setUser(null);
      });
    }
  };


  return <div className={"NavBar"}>
    <div className={"total-players"}>
      <div className={"material-icons icon"}>accessibility_new</div>
      <div className={"label"}>{addComma(totalPlayers)}</div>
    </div>
    <div className={"time"} onClick={handleTimeFormat}>
      {time}
    </div>
    <div className={"user"} onClick={handleSignOut}>
      <div className={"material-icons icon"}>person</div>
      <div className={"label"}>{user.email}</div>
    </div>
  </div>
}

export default NavBar;
