import './App.scss';
import {Route, Routes} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Participant from "./layout/Participant";
import {UserContext} from "./Contexts";
import {useEffect, useState} from "react";
import Controller from "./layout/Controller";
import Cast from "./layout/Cast";
import Login from "./layout/Login";
import RequireAuth from "./layout/RequireAuth";
import Loader from "./components/Loader";

function App() {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, u => {
      if (u) setUser(u);
      setLoading(false);
    }, err => {
      alert('Could not initialize. Please check your internet connection or refresh this page.');
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const userValue = {user, setUser};

  return loading ? <Loader /> :
    <UserContext.Provider value={userValue}>
      <div className="App">
        <Routes>
          <Route path={"/admin/login"} element={<Login />} exact />
          <Route path={"/admin/*"} element={
            <RequireAuth loginPath={"/admin/login"}>
              <Routes>
                <Route index element={<Controller />} />
                <Route path={"/controller"} element={<Controller />} exact />
                <Route path={"/cast"} element={<Cast />} exact />
              </Routes>
            </RequireAuth>
          }/>
          <Route path={"/participant"} element={<Participant />} exact />
          <Route path={"/"} element={<Participant />} exact />
          <Route path={"*"} element={<div style={{padding: '12px'}}>Not Found</div>} />
        </Routes>
      </div>
    </UserContext.Provider>;
}

export default App;
