import './Controller.scss';
import {useContext, useEffect, useState} from "react";
import {getDatabase, set, ref, increment, onValue} from "firebase/database";
import NavBar from "./controller/NavBar";
import {UserContext} from "../Contexts";
import ControlPanel from "./controller/ControlPanel";
import {cast} from "../api";

import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import Browser from "../components/Browser";
import PlayerActivity from "./controller/PlayerActivity";


export default function Controller() {

  const {user} = useContext(UserContext);
  const [game, setGame] = useState('');
  const [castingGame, setCastingGame] = useState('');
  const [castData, setCastData] = useState(null);

  useEffect(() => {
    const db = getDatabase();

    // Game status
    const unsubscribeGameListener = onValue(ref(db, 'cast/game'), snapshot => {
      setGame(snapshot.val() ?? '');
    }, {
      onlyOnce: true
    });

    const unsubscribeCastListener = onValue(ref(db, 'cast/game'), snapshot => {
      setCastingGame(snapshot.val() ?? '');
    });

    return () => {
      unsubscribeGameListener();
      unsubscribeCastListener();
    };
  }, []);

  const handleGame = game => {
    setGame(game);
  };

  const handleCast = (game, gameConfig, playerConfig) =>  {
    cast(game, gameConfig, playerConfig);
  }

  return <div className={"Controller"}>
    <ToastContainer
      position={"top-center"}
      autoClose={5000}
    />
    <NavBar />
    <div className={"body"}>
      <ControlPanel game={game} onGame={handleGame} onCast={handleCast} castingGame={castingGame} />
      <div className={"center"}>
        <Browser
          className={"cast"}
          url={`${process.env.REACT_APP_WEB_URL}/admin/cast/`}
        />
        {/*<PlayerActivity />*/}
      </div>
      <div className={"right"}>
        <Browser
          className={"player"}
          url={`${process.env.REACT_APP_WEB_URL}/?id=5&name=John`}
        />
      </div>
    </div>
  </div>;
};
