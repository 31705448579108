import Section from "../../Section";
import {toast} from 'react-toastify';

function ResetGameButton ({onReset = () => {},
                            label="Reset Game",
                            confirmMessage="RESET ALL CONFIGURATIONS & USER DATA OF THIS GAME. Proceed?",
                            doubleConfirmQuestion='Enter "reset game" to confirm',
                            doubleConfirmAnswer='reset game',
                            successMessage='Game has been reset!',
                          }) {

  const handleClick = () => {
    if (window.confirm(confirmMessage)) {
      if (doubleConfirmAnswer === window.prompt(doubleConfirmQuestion)) {
        onReset();
        toast.info(successMessage);
      } else {
        toast.warn('Wrong phrase entered. Nothing happened.');
      }
    }
  }

  return <Section className={"ResetGameButton text-center"}>
    <button className={"btn btn-text text-danger"} onClick={handleClick}>
      {label}
    </button>
  </Section>;
}

export default ResetGameButton;
