import './Lightning.scss';
import LightningBolt from "./LightningBolt";
import {useEffect, useState} from "react";

const Lightning = () => {

  const [tick, setTick] = useState(0);
  const maxTick = 6;

  useEffect(() =>{

    for (let i = 0 ; i < maxTick ; ++i) {
      setTimeout(() => {
        setTick(tick => tick + 1);
      }, (Math.random() * 200 + 100) * i);
    }
  }, []);

  return <div className={"Lightning"}>
    {tick >= 0 && <LightningBolt key={tick} />}
  </div>

};

export default Lightning;
